import { Mark } from 'tiptap';
import { removeMark, toggleMark } from 'tiptap-commands';
// import { getMarkRange } from 'tiptap-utils';

/**
 * FontSize - Extends the built in Mark from tiptap allowing
 * the editor to <drumroll> set the font-size.
 */
export default class FontFamily extends Mark {
  get name() {
    return 'fontFamily';
  }

  get schema() {
    return {
      attrs: { fontFamily: { default: '' } },
      parseDOM: [
        {
          style: 'font-family',
          getAttrs: (value) => (value ? { fontFamily: value } : ''),
        },
      ],
      toDOM: (mark) => ['span', { style: `font-family: ${mark.attrs.fontFamily}` }, 0],
    };
  }

  commands({ type }) {
    return (attrs) => {
      if (attrs.fontFamily && attrs.fontFamily != null) {
        return toggleMark(type, attrs);
      }
      return removeMark(type);
    };
  }
}

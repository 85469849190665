import Vue from 'vue';
import Vuex from 'vuex';
import axios from "@/directives/axios.js";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    evangelioDia: "",
    lecturaDia:"",
    allImagenes: [],
    allGalerias: [],
    allNoticias: [],
    allNoticiasTotal: []
  },
  getters: {
    getEvangelioDia(state){
      return state.evangelioDia;
    },
    getLecturaDia(state){
      return state.lecturaDia;
    },
    getAllImages(state) {
      return state.allImagenes.reverse();
    },
  },
  mutations: {
    changeEvangelioDia(state, data){
      state.evangelioDia = data;
    },
    changeLecturaDia(state, data){
      state.lecturaDia = data;
    },
    inicialTodasGalerias(state) {
      axios({
        method: "GET",
        url: `/imagenes/todasGalerias`,
        headers: { "Content-Type": "application/json" },
      })
        .then((data) => {
          state.allGalerias = data.data;
        })
        .catch(() => {
        });
    },
    initialAllImages(state) {
      console.log(state);
    },
  },
  actions: {
    changeAllImages(context) {
      context.commit("initialAllImages");
    },
    initialDataChange(context) {
      context.commit("initialAllImages");
    },
  },
  modules: {
  }
})

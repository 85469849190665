<template>
  <div class="bodyCarrete" v-if="listaGallery.length > 0">
    <h1 v-if="id != 2" class="title">{{ title }}</h1>
    <div class="wrapper">
      <template v-for="(item, index) in listaGallery">
        <cardView
          :imageSrc="item.urlImage"
          :imagetitle="item.titulo"
          :imageDescription="item.description"
          :key="index"
          :index="index"
          @index="getIndex"
        />
      </template>
    </div>
    <div v-if="enablebackButton" class="backButton">
      <img
        :src="'https://santodomingocartagena.com/img/icon/backButton.png'"
        alt="atras"
        @click="$router.go(-1)"
      />
    </div>
    <LightBox
      ref="lightbox"
      :show-caption="true"
      :show-light-box="false"
      :media="onlyImage"
      :interfaceHideTime="300000"
    />
  </div>
</template>

<script>
import axios from "@/directives/axios.js";
import cardView from "@/components/galeria/CardView.vue";
import LightBox from "vue-it-bigger";
import("vue-it-bigger/dist/vue-it-bigger.min.css"); // when using webpack

export default {
  name: "gridFotos",
  props: ["id", "listaImagenes"],
  components: {
    cardView,
    LightBox,
  },
  data() {
    return {
      lista: [],
      listaGallery: [],
      title: "",
      onlyImage: [],
      index: null,
      imageSrc: undefined,
      enablebackButton: true,
      base: location.protocol + "//" + location.hostname,
    };
  },
  watch: {},
  methods: {
    changeData() {
      let id = this.id != undefined ? this.id : this.$route.query.id;
      let pass = 0;

      axios({
        method: "GET",
        url: `/imagenes/galeriaImagenes?id=` + id,
        headers: { "Content-Type": "application/json" },
      })
        .then((data) => {
          if (data.data.length > 0) {
            let galeria = data.data.galerias;
            this.title = galeria.titulo;
            this.listaGallery = [];
            this.lista = galeria.imagenes;
            this.lista.forEach((element) => {
              if (pass === 0) {
                this.imageSrc = element.imagen;
                pass++;
              }
              this.listaGallery.push({
                urlImage: element.imagen,
              });
            });
          }
          this.modifyMeta();
        })
        .catch(() => {});
    },
    youTubeGetID(url) {
      var ID = "";
      url = url
        .replace(/(>|<)/gi, "")
        .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_]/i);
        ID = ID[0];
      } else {
        ID = url;
      }
      return ID;
    },
    getIndex(event) {
      this.index = event.key;
      if (this.onlyImage.length === 0) {
        this.lista.forEach((element) => {
          if (!element.urlImage.includes("http")) {
            this.onlyImage.push({
              type: "image",
              thumb: `${this.base}/img/gallery/` + element.urlImage,
              src: `${this.base}/img/gallery/` + element.urlImage,
            });
          } else if (element.urlImage.includes("youtube")) {
            let idVideo = this.youTubeGetID(element.urlImage);
            this.onlyImage.push({
              type: "youtube",
              thumb: "https://img.youtube.com/vi/" + idVideo + "/hqdefault.jpg",
              id: idVideo,
            });
          }
        });
      }
      this.$refs.lightbox.showImage(this.index);
    },
    modifyMeta() {
      document.querySelector('head meta[property="og:url"]').content =
        document.location.href;
    },
  },
  mounted() {
    if (this.id) {
      this.changeData();
    } else if (this.listaImagenes) {
      this.listaGallery = this.listaImagenes;
      this.lista = this.listaImagenes;
    }
  },
};
</script>
<style>
.bodyCarrete {
  padding-top: 150px;
  min-height: calc(100vh);
}
.backButton img {
  width: 45px;
  display: flex;
  margin-left: 20px;
  transition: 1s;
}
.backButton :hover {
  width: 55px;
  transition: 1s;
}
.backButton :hover {
  cursor: pointer;
}
.backButton {
  padding: 20px;
  font-size: 20px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
}
</style>

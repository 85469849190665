import { Node } from 'tiptap';
import { replaceText } from 'tiptap-commands';

export default class Field extends Node {
  get name() {
    return 'field';
  }

  get schema() {
    return {
      attrs: {
        id: {},
        label: {},
      },
      group: 'inline',
      inline: true,
      selectable: true,
      atom: true,
      toDOM: (node) => [
        'span',
        {
          class: 'custom-field',
          'data-field-id': node.attrs.id,
          id: node.attrs.id,
        },
        `${node.attrs.label}`,
      ],
      parseDOM: [
        {
          tag: 'span[data-field-id]',
          getAttrs: (dom) => {
            const id = dom.getAttribute('data-field-id');
            const label = dom.innerText;
            return { id, label };
          },
        },
      ],
    };
  }

  commands({ schema }) {
    return (attrs) => replaceText(null, schema.nodes[this.name], attrs);
  }
}

<template>
  <b-col>
    <b-row class="header-caritas">
      <h1>Proyectos de Cáritas Parroquial</h1>
      <h4>
        La acción caritativa de Cáritas se desarrolla por personas voluntarias,
        miembros de la comunidad cristiana tanto de la Armada de Madrid y sus
        familias como de los feligreses de nuestra Parroquia.
      </h4>
    </b-row>
    <b-row v-for="(item, key) in proyectos" :key="key" class="fist-text">
      <b-card v-if="key % 2 !== 0" no-body class="overflow-hidden">
        <b-row class="rowCard" no-gutters>
          <b-col class="col1a" :style="{ backgroundImage: 'url(' + item.background + ')' }" md="6">
            <h2 v-html="item.titulo"></h2>
          </b-col>
          <b-col md="6">
            <b-card-body title="">
              <b-card-text>
                <p v-html="item.texto"></p>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
      <b-card v-else no-body class="overflow-hidden">
        <b-row class="rowCard" no-gutters>
          <b-col md="6">
            <b-card-body title="">
              <b-card-text>
                <p v-html="item.texto"></p>
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col class="col2a" :style="{ backgroundImage: 'url(' + item.background + ')' }" md="6">
            <h2 v-html="item.titulo"></h2>
          </b-col>
        </b-row>
      </b-card>
    </b-row>
    <b-row>
      <grid-fotos id="2" />
    </b-row>
  </b-col>
</template>
<script>
import GridFotos from "@/components/galeria/GridFotos.vue";
export default {
  components: { GridFotos },
  data() {
    return {
      proyectos: [
        {
          titulo: "Ayuda a familias necesitadas",
          texto:
            "Hasta el mes de marzo de 2024, se han estudiado 41 casos, de los " +
            "que se han ayudado a 38 familias con 236 actuaciones, con 122 " +
            "beneficiarios totales. Esta ayuda ha consistido en aportar " +
            "alimentos, compra de libros escolares, andador de " +
            "discapacitados, pago de recibos de colegios, electricidad, agua, " +
            "etc.",
            background:"https://www.caritas.es/main-files/uploads/sites/20/2017/11/1200x695_slider_accionsocial.jpg"
        },
        {
          titulo: "Ayuda a Yibuti",
          texto:
            "Desde el comienzo de la actividad de CP se implementó este " +
            "proyecto consistente en enviar ayuda material (alimentos, " +
            "material escolar, ayuda económica, etc) a Cáritas Yobuti con los " +
            "buques de la Armada destacados en la operación Atalanta. " +
            "Actualmente se está ayudando al obispo de Yibuti a sostener una " +
            "pequeña escuela para niños y jóvenes.",
            background:"https://www.caritas.es/main-files/uploads/sites/20/2017/11/1200x695_slider_accionsocial.jpg"
        },
        {
          titulo: "Colaboración con Cáritas Parroquiales Diocesana",
          texto:
            "Cuando pasó la fase más crítica de la pandemia el proyecto COVID-19 " +
            "derivó en este proyecto de ayuda a Cáritas Parroquiales de Parroquias territoriales de Madrid. " +
            "Actualmente apoyamos a las Parroquias de Jesús de Nazaret (Vallecas), San Pablo (Vallecas) " +
            "y San Miguel Arcángel (Carabanchel), mediante la aportación de alimentos y en la financiación de la organización de campamentos juveniles.",
            background:"https://www.caritas.es/main-files/uploads/sites/20/2017/11/1200x695_slider_haztevoluntario.jpg"
        },
        {
          titulo: "Ayuda a Mayores",
          texto:
            "Este proyecto está dirigido y gestionado por los Servicios Centrales de Cáritas Castrense, " +
            "al que están adscritas dos de las voluntarias de CP Castrense Armada Madrid",
            background:"https://www.caritas.es/main-files/uploads/sites/20/2020/11/Cartel-Cerca-de-ti-2_page-0001-R-portada.jpg"
        },
        {
          titulo: "Colaboración en emergencias nacionales e internacionales",
          texto:
            "Caritas Parroquial contribuye, a través de los Servicios Centrales de Cáritas Castrense con la las llamada " +
            "a la Cooperación de Emergencia de Cáritas Españolas, como han sido las ayudas a: </br>" +
            "<b>Isla de la Palma tras la erupción del volcán.</b></br>" +
            "<b>Ucrania.</b></br>" +
            "<b>Acogida a ucranianos desplazados en Valencia.</b></br>" +
            "<b>Terremoto de Siria y Turquía.</b></br>",
            background:""
        },
      ],
    };
  },
  name: "VistaCaritasProyectos",
  mounted(){
  }
};
</script>
<style scoped>
#app{
  background: white;
}
.col1a {
  min-height: 250px;
  position: relative;
  box-shadow: inset 20px 0px 20px 5px white, inset -20px 0px 20px 5px white;
  background-position: center;
}
.col2a{
  min-height: 250px;
  position: relative;
  box-shadow: inset 20px 0px 20px 5px white, inset -20px 0px 20px 5px white;
  background-position: bottom;
}

.card {
  margin-bottom: 25px !important;
  border: none !important;
  box-shadow: 0px 0px 20px 0px #cd222dba;
}
.wrapper {
  max-width: 83% !important;
  margin: 0 auto !important;
}
h2 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.backButton {
  display: none !important;
}
.bodyCarrete {
  padding-top: 0 !important;
  min-height: 0 !important;
}
.header-caritas {
  font-size: revert-layer;
  color: white;
  max-width: 80%;
  margin: 0 auto;
  height: 270px;
  padding: 115px;
  text-align: center;
  background-size: cover;
  background-image: url("https://www.caritas.es/main-files/uploads/sites/20/2017/11/1200x695_slider_cooperacioninternacional.jpg");
}
.body {
  padding-top: 75px;
}
p {
  font-size: 18px;
}
.fist-text {
  max-width: 80%;
  margin: 0 auto;
  padding-top: 30px;
}
.div-donacion {
  background: #cd222d;
  color: white;
  margin-top: 0;
}
@media (min-width: 990px) {
  .div-donacion {
    margin-top: 0;
  }
}
@media (max-width: 990px) {
  .div-donacion {
    margin-top: 0;
  }
}
</style>

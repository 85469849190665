<template>
  <b-modal
    class="popupNews"
    size="xl"
    :id="modalId"
    :hide-footer="true"
    :hide-header="false"
  >
    <template v-slot:modal-header>
      <div class="d-flex">
        <img
          style="height: 60px"
          :src="require('@/assets/img/logo_santo_domingo.png')"
          :alt="$root.constantes.logo_nombre_sitio"
        />
      </div>
      <div class="pointer justify-self-center">
        <closeicon @click="closeModal" />
      </div>
    </template>
    <b-col class="text-center" v-if="direccion != '' && mailIglesia != '' && tlfIglesia !=''">
      <h3 id="header-contact">Contacto</h3>
      <b-row class="mt-2">
        <b-col>
          <b-row
            ><b-icon
              style="color: green"
              class="icons h1 mt-5"
              icon="telephone"
          /></b-row>
          <b-row class="mt-2"
            ><a :href="`tel:${tlfIglesia}`" class="text-center">{{ tlfIglesia }}</a></b-row
          >
        </b-col>
        <b-col>
          <b-row
            ><b-icon style="color: green" class="icons h1 mt-5" icon="envelope"
          /></b-row>
          <b-row class="mt-2"
            ><a :href="`mailto:${mailIglesia}`" class="text-center"
              >{{ mailIglesia }}</a
            ></b-row
          >
        </b-col>
        <b-col>
          <b-row
            ><b-icon style="color: green" class="icons h1 mt-5" icon="map"
          /></b-row>
          <b-row class="mt-2"
            ><p class="text-center">
              {{ direccion }}
            </p></b-row
          >
        </b-col>
      </b-row>
    </b-col>
    <b-col class="text-center mt-5" v-if="tlfCaritas != '' && mailCaritas != ''">
      <h3 id="header-contact">Caritas Castrense Parroquial</h3>
      <b-row class="mt-2">
        <b-col v-if="tlfCaritas != ''">
          <b-row
            ><b-icon
              style="color: green"
              class="icons h1 mt-5"
              icon="telephone"
          /></b-row>
          <b-row class="mt-2"
            ><a :href="`tel:${tlfCaritas}`" class="text-center">{{ tlfCaritas }}</a></b-row
          >
        </b-col>
        <b-col v-if="mailCaritas != ''">
          <b-row
            ><b-icon style="color: green" class="icons h1 mt-5" icon="envelope"
          /></b-row>
          <b-row class="mt-2"
            ><a :href="`mailto:${mailCaritas}`" class="text-center">{{
              mailCaritas
            }}</a></b-row
          >
        </b-col>
      </b-row>
    </b-col>
  </b-modal>
</template>

<script lang="ts">
import closeicon from "../assets/components/close.vue";

export default {
  name: "modalContacto",

  components: {
    closeicon,
  },

  data() {
    return {
      mailIglesia: this.$root.constantes.email.iglesia,
      tlfIglesia: this.$root.constantes.tlf.iglesia,
      direccion: this.$root.constantes.direccion,

      mailCaritas: this.$root.constantes.email.caritas,
      tlfCaritas: this.$root.constantes.tlf.caritas,
    };
  },

  props: ["modalId"],

  computed: {},
  methods: {
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>
<style>
#header-contact {
  border-bottom: 1px solid;
}
</style>
<style scoped>
.d-flex {
  margin: 0 auto !important;
}
</style>

import { render, staticRenderFns } from "./PaginaPrincipal.vue?vue&type=template&id=3201c672&scoped=true"
import script from "./PaginaPrincipal.vue?vue&type=script&lang=js"
export * from "./PaginaPrincipal.vue?vue&type=script&lang=js"
import style0 from "./PaginaPrincipal.vue?vue&type=style&index=0&id=3201c672&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3201c672",
  null
  
)

export default component.exports
import { Mark } from 'tiptap';
import { removeMark, toggleMark } from 'tiptap-commands';

/**
 * FontSize - Extends the built in Mark from tiptap allowing
 * the editor to <drumroll> set the font-size.
 */
export default class FontSize extends Mark {
  get name() {
    return 'fontSize';
  }

  get schema() {
    return {
      attrs: { fontSize: { default: '1em' } },
      parseDOM: [
        {
          style: 'font-size',
          getAttrs: (value) => (value ? { fontSize: value } : ''),
        },
      ],
      toDOM: (mark) => ['span', { style: `font-size: ${mark.attrs.fontSize}` }, 0],
    };
  }

  commands({ type }) {
    return (attrs) => {
      if (attrs.fontSize && attrs.fontSize != null) {
        return toggleMark(type, attrs);
      }
      return removeMark(type);
    };
  }
}

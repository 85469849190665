<template>
  <div id="mantenimiento">
    <div id="loadingwebsite">
      <img animation: class="loadingLogo" alt="logo Parroquia de la armada Nuestra señora del carmen"
      :src="require('@/assets/img/logo_santo_domingo.png')"/>
    </div>
    <div>
      <h2>Pagina en mantenimiento</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "mantenimientoView",
  components: {},

  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>
<style scoped>
body {
  height: 100%;
  width: 100%;
}
h2 {
  position: absolute;
  bottom: 50%;
  right: 0;
  left: 0;
  margin: 0 auto;
}
.loadingLogo {
  position: absolute;
  top: 25%;
  right: 0;
  left: 0;
  margin: 0 auto;
  animation: mymove 2s infinite;
}
body{
  background: rgb(27,90,140,1) !important;;
}
</style>

<template>
  <div
    class="cardView"
    v-if="idGallery"
    @click="$emit('getIdGalery', { id: idGallery, title: imagetitle })"
  >
    <div class="hover hover-2 text-white rounded">
      <div class="image-container">
        <img
          style="width: 100%"
          :src="`${this.base}/img/minGallery/` + imageSrc"
          :alt="imageDescription"
        />
      </div>
      <div class="hover-overlay"></div>
      <div class="hover-2-content px-5 py-4">
        <h4 class="hover-2-title text-uppercase font-weight-bold mb-0">
          <span class="font-weight-light">{{ imagetitle }} </span>
        </h4>
        <p class="hover-2-description text-uppercase mb-0">
          {{ imageDescription }}
        </p>
      </div>
    </div>
  </div>

  <div v-else class="item cardView">
    <div class="hover hover-2 text-white rounded">
      <img
        style="width: 100%"
        v-if="isImage == 'image'"
        :src="`${this.base}/img/minGallery/` + imageSrc"
        :alt="imageDescription"
        @click="$emit('index', { key: index })"
      />
      <img
        style="width: 100%"
        v-if="isImage == 'video'"
        :src="imageSrc"
        :alt="imageDescription"
        @click="$emit('index', { key: index })"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "cardView",
  props: ["imageSrc", "imagetitle", "imageDescription", "idGallery", "index"],
  data() {
    return {
      base: location.protocol + "//" + location.hostname,
    };
  },
  components: {},
  methods: {},
  computed: {
    isImage() {
      if (this.imageSrc && this.imageSrc.includes("http")) {
        return "video";
      } else {
        return "image";
      }
    },
  },
};
</script>
<style>
.item {
  margin: 12px;
}
span,
h4,
p {
  user-select: none;
}
.hover {
  overflow: hidden;
  position: relative;
  padding-bottom: 60%;
}

.hover-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: 90; */
  transition: all 0.4s;
}

.hover img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
}

.hover-content {
  position: relative;
  /* z-index: 99; */
}

.hover-2 .hover-overlay {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1));
}

.hover-2-title {
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  width: 100%;
  /* z-index: 99; */
  transition: all 0.3s;
}

.hover-2-description {
  width: 100%;
  position: absolute;
  bottom: 0;
  opacity: 0;
  left: 0;
  text-align: center;
  /* z-index: 99; */
  transition: all 0.3s;
}

.hover-2:hover .hover-2-title {
  transform: translateY(-1.5rem);
}

.hover-2:hover .hover-2-description {
  bottom: 0.5rem;
  opacity: 1;
}

.hover-2:hover .hover-overlay {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.1));
}
.cardView :hover {
  cursor: pointer;
}
@media (max-width: 40rem) {
  .cardView {
    margin-bottom: 10px;
  }
}
</style>
<style scoped>
.image-container {
  width: 100%;
  overflow: hidden;
  border-radius: 0.5rem; /* Opcional: redondea las esquinas */
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajusta la imagen sin deformarla */
  object-position: center; /* Centra la imagen */
}
</style>

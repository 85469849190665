<template>
  <div v-if="metadata != null">
    <b-card class="cards_last_news">
      <article class="blog-card" @click="redireccion(metadata.url)">
        <img v-if="getImagen(metadata.metadata) != ''" class="post-image" :src="getImagen(metadata.metadata)" />

        <div class="article-details">
          <!-- titulo-->
          <h3 class="post-title">{{ getTitulo(metadata.metadata) }}</h3>
          <!-- principio de la noticia-->
          <p class="post-description">
            {{ getDescripcion(metadata.metadata) }}
          </p>
          <!-- boton de redireccion-->
        </div>
        <div class="autor">
          {{ getAutor(metadata.metadata) }}
        </div>
      </article>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "sitiosInteres",
  props: ["data"],
  data() {
    return {
      metadata: null,
      infoSitio: undefined,
    };
  },
  components: {},
  mounted() {
    this.metadata = this.data;
  },
  methods: {
    redireccion(url) {
      window.open(url, "_blank");
    },
    getTitulo(element) {
      var texto = "";
      var key = "";
      for (let index = 0; index < Object.keys(element).length; index++) {
        key = Object.keys(element)[index];
        if (key.includes("title")) {
          texto = element[key];
          break;
        }
      }
      return texto;
    },
    getAutor(element) {
      var texto = "";
      var key = "";
      for (let index = 0; index < Object.keys(element).length; index++) {
        key = Object.keys(element)[index];
        if (key.includes("authors")) {
          texto = element[key];
          break;
        }
      }
      return texto;
    },
    getDescripcion(element) {
      var texto = "";
      var key = "";
      for (let index = 0; index < Object.keys(element).length; index++) {
        key = Object.keys(element)[index];
        if (key.includes("description")) {
          texto = element[key];
          break;
        }
      }
      return texto;
    },
    getImagen(element) {
      var texto = "";
      var key = "";
      for (let index = 0; index < Object.keys(element).length; index++) {
        key = Object.keys(element)[index];
        if (key.includes("image")) {
          texto = element[key];
          break;
        }
      }
      return texto;
    },
  },
  computed: {},
};
</script>
<style scoped>
h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.post-description {
  margin-right: 25px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.news-date {
  top: 15px;
  right: 15px;
  min-height: 48px;
  min-width: 48px;
  position: absolute;
  text-align: center;
  padding-bottom: 1px;
  margin-bottom: 20px;
  border-bottom: 2px solid;
  opacity: 0.7;
  transition: opacity 0.3s;
}
.news-date__title {
  display: block;
  font-size: 32px;
  font-weight: 500;
}
.news-date__txt {
  font-size: 16px;
}
.cards_last_news {
  margin: 15px;
}
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
* {
  box-sizing: border-box;
}

#root {
  width: 30rem;
  height: 13.625rem;
}

.blog-card {
  display: flex;
  flex-direction: row;
  background: #fff;
  box-shadow: 0 0.1875rem 1.5rem rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem;
  overflow: hidden;
  max-height: 500px;
}

.card-link {
  position: relative;
  display: block;
  color: inherit;
  text-decoration: none;
}
.card-link:hover .post-title {
  transition: color 0.3s ease;
  color: #e04f62;
}

img.post-image {
  transition: opacity 0.3s ease;
  display: block;
  width: 100%;
  object-fit: cover;
  transition: 0.5s;
}

img.post-image:hover {
  transition: 0.5s;
  transform: scale(1.1);
  cursor: pointer;
}

.article-details {
  padding: 1.2rem;
}

.post-category {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.0625rem;
  margin: 0 0 0.75rem 0;
  padding: 0 0 0.25rem 0;
  border-bottom: 0.125rem solid #ebebeb;
}
.post-title {
  transition: color 0.3s ease;
  font-size: 1.125rem;
  line-height: 1.4;
  color: #121212;
  font-weight: 700;
  margin: 0 0 0.5rem 0;
}
.post-button {
  font-size: 0.875rem;
  line-height: 1;
  margin: 1.125rem 0 0 0;
  padding: 1.125rem 0 0 0;
  border-top: 0.0625rem solid #ebebeb;
}
.post-button:hover {
  color: #e04f62;
  cursor: pointer;
}
@media (max-width: 200rem) {
  #root {
    width: 18rem;
    height: 27.25rem;
  }

  .blog-card {
    flex-wrap: wrap;
  }
}
@supports (display: grid) {
  #root {
    grid-area: main;
    align-self: center;
    justify-self: center;
  }

  .post-image {
    height: 100%;
  }

  .blog-card {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
  }

  @media (max-width: 200rem) {
    .blog-card {
      grid-template-columns: auto;
      grid-template-rows: 12rem 1fr;
    }
    .news-date {
      background: white;
    }
    .cards_last_news {
      margin: 30px;
    }
  }
}
</style>
import axios from 'axios';

// Configuración global de Axios
axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
    withCredentials: true,
    //baseURL: 'http://localhost/api_santodomingo/public/api',
    baseURL: '/api',
    //timeout: 5000, // Tiempo de espera para las solicitudes
  });

export default axiosInstance;
<template>
  <div id="footer" class="footerGroup">
    <b-row>
      <b-col>
        <div>
          <b-row>
            <b-icon class="icons h3 mt-5" icon="telephone" />
          </b-row>
          <b-row class="mt-2">
            <a :href="`tel:${tlfIglesia}`" class="text-center">{{ tlfIglesia }}</a>
          </b-row>
        </div>
      </b-col>
      <b-col>
        <b-row
          ><b-icon  class="icons h3 mt-5" icon="envelope"
        /></b-row>
        <b-row class="mt-2"
          ><a
          :href="`mailto:${mailIglesia}`"
            class="text-center"
            >{{ mailIglesia }}</a>
          </b-row>
      </b-col>
      <b-col>
        <b-row><b-icon  class="icons h3 mt-5" icon="map" /></b-row>
        <b-row class="mt-2"><p class="text-center">{{ direccion }}</p></b-row>
        </b-col>
    </b-row>
  </div>
</template>

<script>
import { Constantes } from "@/directives/Constantes";
export default {
  name: "footer-site",
  components: {},
  data() {
    return {
      mailIglesia: Constantes.email.iglesia,
      tlfIglesia: Constantes.tlf.iglesia,
      direccion: Constantes.direccion,
    };
  },
  methods: {},
};
</script>
<style scoped>
.row {
  margin: 0;
}
.footerGroup {
  background-color: #444;
  color: antiquewhite;
}
.icon-footer :hover {
  color: blue;
}
.youtube-icon:hover{
color: red;
cursor: pointer;
}
a{
  color: aliceblue;
}
.facebook-icon:hover{
  color:#3b5998;
  cursor: pointer;
}
</style>

<template>
  <div class="cardViewconfconf" v-if="idGallery">
    <div class="hoverr hoverr-2 text-white rounded">
      <img
        v-if="imageSrc && imageSrc.includes('img') && idGallery == 0"
        style="width: 25%; margin-left: 37%"
        :src="imageSrc"
        :alt="imageDescription"
      />
      <img
        v-else
        style="width: 100%"
        :src="`${this.base}/img/minGallery/` + imageSrc"
        :alt="imageDescription"
      />
      <div class="hoverr-overlay"></div>
      <div class="hoverr-2-content px-5 py-4">
        <h4 class="hoverr-2-title text-uppercase font-weight-bold mb-0">
          <span class="font-weight-light">{{ imagetitle }} </span>
        </h4>
        <p
          v-if="idGallery != 0 && idGallery != 1"
          class="hoverr-2-description text-uppercase mb-0"
        >
          <b-icon
            v-b-tooltip.hover="'Galeria de imagenes'"
            class="iconn"
            icon="images"
            style="color: blue"
            @click="
              $emit('iconClick', {
                id: idGallery,
                title: imagetitle,
                action: 'openGallery',
              })
            "
          />
          <b-icon
            v-if="idGallery != 2"
            v-b-tooltip.hover="'Eliminar Galeria'"
            class="iconn"
            icon="trash"
            style="color: green"
            @click="
              $emit('iconClick', { id: idGallery, action: 'deleteGallery' })
            "
          />
          <b-icon
            v-b-tooltip.hover="'Editar Galeria'"
            class="iconn"
            icon="pencil-square"
            style="color: green"
            @click="
              $emit('iconClick', {
                id: idGallery,
                imageSrc: imageSrc,
                tituloNoticia: imagetitle,
                descripNoticia: imageDescription,
                action: 'editGallery',
              })
            "
          />
        </p>
        <p
          v-else-if="idGallery == 1"
          class="hoverr-2-description text-uppercase mb-0"
        >
          <b-icon
            v-b-tooltip.hover="'Galeria de imagenes'"
            class="iconn"
            icon="images"
            style="color: blue"
            @click="
              $emit('iconClick', {
                id: idGallery,
                title: imagetitle,
                action: 'openGallery',
              })
            "
          />
        </p>
        <p v-else class="hoverr-2-description text-uppercase mb-0">
          <b-icon
            v-b-tooltip.hover="'Crear Galeria'"
            class="iconn"
            icon="pencil-square"
            style="color: green"
            @click="
              $emit('iconClick', {
                id: idGallery,
                title: imagetitle,
                action: 'newGallery',
              })
            "
          />
        </p>
      </div>
    </div>
  </div>
  <!-- Actua para el selector de imagenes -->
  <div
    @click="$emit('selectedImage', imageSrc)"
    v-else
    class="itemm cardViewconf"
    :style="`${isSelected}`"
  >
    <div class="hoverr hoverr-2 text-white rounded">
      <img
        style="width: 100%"
        :src="`${this.base}/img/minGallery/` + imageSrc"
        :alt="imageDescription"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "cardViewconf",
  props: [
    "imageSrc",
    "imagetitle",
    "imageDescription",
    "idGallery",
    "index",
    "imagesSelected",
  ],
  data() {
    return { base: location.protocol + "//" + location.hostname };
  },
  components: {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
.iconn {
  width: 28px;
  height: 28px;
  margin: 5px;
  transition: all 0.3s;
}
.iconn:hover {
  cursor: pointer;
  transition: all 0.3s;
  width: 32px;
  height: 32px;
}
.itemm {
  padding: 3px;
  background: brown;
}
.itemm:hover {
  cursor: pointer;
}
span,
h4,
p {
  user-select: none;
}
.hoverr {
  overflow: hidden;
  position: relative;
  padding-bottom: 60%;
}

.hoverr-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
  transition: all 0.4s;
}

.hoverr img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
}

.hoverr-content {
  position: relative;
  z-index: 99;
}

.hoverr-2 .hoverr-overlay {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1));
}

.hoverr-2-title {
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 99;
  transition: all 0.3s;
}

.hoverr-2-description {
  width: 100%;
  position: absolute;
  bottom: 0;
  opacity: 1;
  left: 0;
  text-align: center;
  z-index: 99;
  transition: all 0.3s;
}
/* .cardViewconf :hoverr {
  cursor: pointer;
} */
@media (max-width: 40rem) {
  .cardViewconf {
    margin-bottom: 10px;
  }
}
</style>

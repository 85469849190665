<template>
  <div class="bodyMedia">
    <div class="wrapper" v-if="lista.length > 0">
      <template v-for="(item, index) in lista">
        <cardView
          :imageSrc="item.imagenGaleria"
          :imagetitle="item.titulo"
          :key="index"
          :idGallery="item.id"
          @getIdGalery="getId"
        />
      </template>
    </div>
  </div>
</template>

<script>
import cardView from "@/components/galeria/CardView.vue";
import axios from "@/directives/axios.js";

export default {
  name: "vistaGaleria",
  props: ["listMedia"],
  metaInfo() {
    return {
      title: "Parroquia de la Armada - Multimedia",
      meta: [
        {
          name: "description",
          content:
            `imagenes y videos sobre de actividades de la ${this.$root.constantes.logo_nombre_sitio}`,
        },
      ],
      link: [
        {
          rel: "canonical",
          href: `https://santodomingocartagena.com${this.$route.fullPath}`,
        },
      ],
    };
  },
  components: {
    // loadingWebSite,
    cardView,
  },
  data() {
    return {
      lista: [],
      base: location.protocol + "//" + location.hostname,
    };
  },
  mounted() {
    axios({
      method: "GET",
      url: `/imagenes/todasGalerias`,
    })
      .then((data) => {
        this.lista = data.data.todasGalerias.filter(
          (element) => element.id != 1
        );
        this.lista = this.lista.reverse();
      })
      .catch(() => {});
  },
  methods: {
    getId(value) {
      let title = value.title.replaceAll(" ", "_");
      this.$router.push({
        name: "carrete",
        query: { id: value.id, title: title },
      });
    },
  },
  computed: {},
};
</script>
<style>
.wrapper {
  margin-left: 50px;
  margin-right: 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-rows: auto;
}
.bodyMedia {
  padding-top: 150px;
  min-height: calc(100vh);
}
@media screen and (max-width: 600px) {
  .wrapper {
    margin-left: 20%;
    margin-right: 20%;
    display: block;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 600px) and (max-width: 900px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 900px) and (max-width: 1200px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1750px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
</style>

<template>
  <b-col>
    <b-row class="header-caritas">
      <h1>Cáritas Parroquial</h1>
    </b-row>
    <b-row class="fist-text">
      <b-col sm="12" md="12" lg="8">
        <b-card no-body class="overflow-hidden">
          <b-row class="rowCard" no-gutters>
            <b-col class="col1a fundacion" md="6">
              <h2>Fundación de Cáritas Parroquial</h2>
            </b-col>
            <b-col md="6">
              <b-card-body title="">
                <b-card-text>
                  Tras la erección en 2012 de Cáritas Castrense por el Arzobispo
                  Castrense Monseñor Don Juan del Río, comienzan a establecerse
                  las distintas Cáritas Parroquiales en las Parroquias de su
                  ámbito diocesano castrense, siendo la de la Parroquia
                  Castrense de la Armada de Nuestra Señora del Carmen una de las
                  primeras que se crea en el año 2014 bajo la denominación de
                  <b>Caritas Parroquial Castrense Armada Madrid.</b>
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
        <b-card no-body class="overflow-hidden">
          <b-row class="rowCard" no-gutters>
            <b-col md="6">
              <b-card-body title="">
                <b-card-text>
                  Promover y coordinar iniciativas y promoción a personas
                  necesitadas o en riesgo de exclusión social, y cumplido este
                  objetivo primordial, cooperar con otras Cáritas Parroquiales
                  del entorno territorial.
                </b-card-text>
              </b-card-body>
            </b-col>
            <b-col class="col1a objetivo" md="6">
              <h2>Nuestro Objetivo</h2>
            </b-col>
          </b-row>
        </b-card>
        <b-card no-body class="overflow-hidden">
          <b-row class="rowCard" no-gutters>
            <b-col class="col1a voluntariado" md="6">
              <h2>Voluntariado</h2>
            </b-col>
            <b-col md="6">
              <b-card-body title="">
                <b-card-text>
                  La acción caritativa de Cáritas se desarrolla por personas
                  voluntarias, miembros de la comunidad cristiana tanto de la
                  Armada de Madrid y sus familias como de los feligreses de
                  nuestra Parroquia.
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col sm="12" md="12" lg="4">
        <b-card class="div-donacion">
          <h1>Hazte donante</h1>
          <p>Puedes realizar una donación mediante transferencia bancaria:</p>
          <p>CC Banco de Santander: ES53 0075 0591 1806 0043 3076</p>
          <p>BIZUM: 03202</p>
        </b-card>
        <b-card class="div-donacion">
          <h1>Hazte Socio</h1>
          <p>
            <a href="mailto:elcorreoquequieres@correo.com"
              >Descarga la ficha de nuevo donante</a
            >
            y entregalo en la sacristia haznolo llegar a traves del email:
          </p>
          <p>
            <a href="mailto:cpcarmadamadrid.ccastrense@caritas.es"
              >cpcarmadamadrid.ccastrense@caritas.es</a
            >
          </p>
          <h4>Para solicitud de cualquier tipo de ayuda:</h4>
          <p>
            Móvil (o Whatsapp):
            <a href="tel:+34636513858"> 636 51 38 58</a>
          </p>
          <p>
            Email:
            <a href="mailto:cpcarmadamadrid.ccastrense@caritas.es"
              >cpcarmadamadrid.ccastrense@caritas.es</a
            >
          </p>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <grid-fotos id="2" />
    </b-row>
  </b-col>
</template>
<script>
import GridFotos from "@/components/galeria/GridFotos.vue";
export default {
  components: { GridFotos },
  data() {
    return {};
  },
  name: "VistaCaritasParroquial",
  mounted(){
  }
};
</script>
<style></style>
<style scoped>
a {
  color: antiquewhite;
}
.col1a {
  min-height: 250px;
  position: relative;
  box-shadow: inset 20px 0px 20px 5px white, inset -20px 0px 20px 5px white;
  /* box-shadow: inset -20px 0px 20px 5px white; */
}
.fundacion {
  background-image: url("https://www.caritas.es/main-files/uploads/2017/11/1200x695_slider_Quehemosconseguido.jpg");
}
.objetivo {
  background-image: url("https://www.caritas.es/main-files/uploads/sites/20/2017/11/1200x695_slider_accionsocial.jpg");
  background-position: center;
}
.voluntariado {
  background: url("https://www.caritas.es/main-files/uploads/sites/20/2017/11/1200x695_slider_haztevoluntario.jpg");
  background-position: bottom;
}
.card {
  margin-bottom: 25px !important;
  border: none !important;
  box-shadow: 0px 0px 20px 0px #cd222dba;
}
.wrapper {
  max-width: 83% !important;
  margin: 0 auto !important;
}
h2 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.backButton {
  display: none !important;
}
.bodyCarrete {
  padding-top: 0 !important;
  min-height: 0 !important;
}
.header-caritas {
  max-width: 80%;
  margin: 0 auto;
  height: 270px;
  padding: 115px;
  text-align: center;
  background-size: cover;
  background-image: url("https://www.caritas.es/main-files/uploads/sites/20/2017/11/1200x695_slider_comonosfinanciamos.jpg");
}
.body {
  padding-top: 75px;
}
p {
  font-size: 18px;
}
.fist-text {
  max-width: 80%;
  margin: 0 auto;
  padding-top: 30px;
}
.div-donacion {
  background: #cd222d;
  color: white;
  margin-top: 0;
}
@media (min-width: 990px) {
  .div-donacion {
    margin-top: 0;
  }
}
@media (max-width: 990px) {
  .div-donacion {
    margin-top: 0;
  }
}
</style>

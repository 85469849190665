<template>
  <div v-if="enableWebSite">
    <cabecera class="cabecera" />
    <keep-alive :exclude="['vistaCarrete', 'vistaNoticia', 'HomePage']">
      <router-view class="body" />
    </keep-alive>
    <footer-site></footer-site>
    <div v-if="popUpChange">
      <popup
        v-for="(popUp, index) in popUpList"
        :key="index"
        :popUp="popUp"
        :id="popUp.id"
      />
    </div>
  </div>
  <div v-else>
    <mantenimiento-view />
  </div>
</template>

<script>
import cabecera from "@/components/Cabecera.vue";
import footerSite from "@/components/footer.vue";
import axios from "@/directives/axios.js";
import popup from "@/components/popUp.vue";
import mantenimientoView from "./Mantenimiento.vue";

export default {
  data() {
    return {
      popUpList: [],
      enableWebSite: true,
    };
  },
  metaInfo() {
    return {
      title: this.$root.constantes.logo_nombre_sitio,
      meta: [
        {
          name: "description",
          content:
            "Informacion sobre la Parroquia de la armada Nuestra Señora del Carmen de Madri.",
        },
        {
          name: "keywords",
          content:
            `Nuestra Señora del Carmen de Madrid, ${this.$root.constantes.logo_nombre_sitio}, parroquia castrense de Nuestra Señora del Carmen, misas Madrid, iglesia castrense de Nuestra Señora del Carmen de Madrid, misas ${this.$root.constantes.nombre_sitio}`,
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://parroquiaarmada.com",
        },
      ],
    };
  },
  name: "homeWebSite",
  props: {
    msg: String,
  },
  components: {
    mantenimientoView,
    cabecera,
    footerSite,
    popup,
  },
  computed: {
    popUpChange() {
      if (this.popUpList && this.popUpList.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    getDay() {
      var day = new Date().getDate();
      var year = new Date().getFullYear();
      var month = new Date().getMonth() + 1;
      return (
        year +
        "-" +
        (month < 10 ? "0" + month : month) +
        "-" +
        (day < 10 ? "0" + day : day)
      );
    },
  },
  methods: {
    getPopUps() {
      axios({
        method: "GET",
        url: `/popup/popUpDia?fecha=${this.getDay}`,
        headers: { "Content-Type": "application/json" },
      })
        .then((value) => {
          this.popUpList = value.data.listaPopUp;
        })
        .catch(() => {});
    },
  },
  mounted() {
    console.log("mant");
    if(this.$cookies.get("mantenimiento")){
      alert("cookie de mantenimiento Activada");
      this.enableWebSite = true;
    }
    this.$emit("datosCargados", false);
    this.getPopUps();
  },
};
</script>

<style scoped>
#galeryPhoto {
  z-index: -1;
  position: fixed;
  width: 100%;
}

.lastNews {
  height: 100%;
  /* margin-top: 145px; */
  padding-top: 15px;
}

.cardsHome {
  height: 650px;
}

#titleLastNews {
  border-bottom: 1px solid;
  display: initial;
}
</style>

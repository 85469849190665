<template>
  <div class="bodyNews">
    <div class="NewsCards">
      <template v-for="(item, index) in lista">
        <card-noticias
          :imageSrc="item.imagenportada"
          :newTitle="item.titulo"
          :cuerpoNoticia="item.prenoticia"
          :fechaPublicacion="item.fechapublicacion"
          :key="index"
          :idnoticia="item.paginaPrincipal ? 'caritas-' + item.id : item.id"
          :idGallery="item.idgaleria"
          @getIdNews="getId"
        />
      </template>
    </div>
  </div>
</template>

<script>
import cardNoticias from "@/components/CardNoticias.vue";
import axios from "@/directives/axios.js";

export default {
  metaInfo() {
    return {
      title: "Parroquia de la armada - Noticias",
      meta: [
        {
          name: "description",
          content:
            "Noticias sobre la Parroquia Castrense de la Armada Nuestra Señora del Carmen",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: `https://parroquiaarmada.com${this.$route.fullPath}`,
        },
      ],
    };
  },
  name: "vistaNoticiasCaritas",
  props: [],
  components: {
    cardNoticias,
  },
  data() {
    return {
      lista: [],
    };
  },
  methods: {
    getId(value) {
      this.$router.push({
        name: "noticia",
        query: { id: 'caritas-'+value.id },
      });
    },
  },
  mounted() {
    axios({
      method: "GET",
      url: `/noticiasCaritas/todasNoticias`,
      headers: { "Content-Type": "application/json" },
    })
      .then((data) => {
        this.lista = data.data.todasNoticias.sort(function (a, b) {
          if (a.fechapublicacion > b.fechapublicacion) {
            return -1;
          }
          if (a.fechapublicacion < b.fechapublicacion) {
            return 1;
          }
          return 0;
        });
      })
      .catch(() => {});
  },
};
</script>
<style scoped>
.NewsCards {
  margin-left: 50px;
  margin-right: 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-rows: auto;
}
.bodyNews {
  padding-top: 150px;
  min-height: calc(100vh);
}
@media screen and (max-width: 600px) {
  .NewsCards {
    margin-left: 20%;
    margin-right: 20%;
    display: block;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 600px) and (max-width: 900px) {
  .NewsCards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 900px) and (max-width: 1200px) {
  .NewsCards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1750px) {
  .NewsCards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 1750px) {
  .NewsCards {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
</style>

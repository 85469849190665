<template>
  <div>
    <div>
      <b-button
        @click="GuardarCambios()"
        id="añadir-horarios"
        style="height: 100%"
        >Guardar Cambios</b-button
      >
    </div>
    <div class="accordion" role="tablist" style="margin-left: 50px">
      <b-card
        no-body
        class="mb-1"
        v-for="(item, index) in horarios"
        :key="index"
      >
        <b-card-header>
          <b-icon
            style="cursor: pointer"
            class="m-2"
            icon="trash-fill"
            scale="1"
            variant="danger"
            @click="eliminarHorario(index)"
          />
          <input
            type="text"
            v-model="item.titulo"
            placeholder="Titulo Horario"
          />
          <b-form-checkbox
            :id="item.id"
            v-model="item.activo"
            name="checkbox-1"
          >
            <h5>&nbsp;Horario visible</h5>
          </b-form-checkbox>
        </b-card-header>
        <div
          v-for="(evento, indexEvento) in item.element"
          :key="indexEvento"
          style="text-align: left"
          :id="'collapse' + item.id"
        >
          <b-card-body>
            <div style="display: flex">
              <input
                type="text"
                v-model="evento.evento"
                style="margin-bottom: 10px"
                placeholder="Titulo evento"
              />
              <b-icon
                style="cursor: pointer; text-align: center"
                class="m-2"
                icon="trash-fill"
                scale="1"
                variant="danger"
                @click="eliminarFila(item.element, indexEvento)"
              />
            </div>
            <!-- Se recorre cada evento -->
            <div
              style="display: flex"
              v-for="(itemInt, indexdata) in evento.values"
              :key="indexdata"
            >
              <input type="text" v-model="itemInt[0]" placeholder="Dias" />
              <input
                type="text"
                v-model="itemInt[1]"
                placeholder="Horario (No obligatorio)"
              />
              <b-icon
                style="cursor: pointer"
                class="m-2"
                icon="trash-fill"
                scale="1"
                variant="danger"
                @click="eliminarFila(evento.values, indexdata)"
              />
            </div>
            <b-button
              style="width: 92%; margin-top: 5px"
              @click="aniadirFila(evento.values)"
              ><b-icon icon="plus-lg"
            /></b-button>
          </b-card-body>
        </div>
        <b-button
          style="width: 100%; margin-top: 5px"
          @click="aniadirevento(item.element)"
          ><b-icon icon="plus-lg"
        /></b-button>
      </b-card>
      <div>
        <b-button
          @click="sumarHorario()"
          id="añadir-horarios"
          style="height: 100%"
          ><b-icon icon="plus-lg"
        /></b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "@/directives/axios.js";

export default {
  name: "editorHorarios",

  components: {},
  data() {
    return {
      horarios: undefined,
      textos: [],
    };
  },
  props: [],
  mounted() {
    this.obtenerHorarios();
  },
  computed: {},
  watch: {},
  methods: {
    aniadirevento(item) {
      item.push({ evento: "", values: [] });
    },
    eliminarHorario(index) {
      this.horarios.splice(index, 1);
    },
    eliminarFila(array, index) {
      array.splice(index, 1);
    },
    checklength(index1, object) {
      return object.length == index1 + 1 ? true : false;
    },
    aniadirFila(horariosEvento) {
      horariosEvento.push([[], []]);
    },
    sumarHorario() {
      this.horarios.push({
        activo: false,
        element: [],
        id: "item" + this.horarios.length,
        titulo: "",
      });
    },
    obtenerHorariosValue(value) {
      var dias = Object.keys(value)[0];
      if (Object.values(value)[0] != "") {
        dias += ": " + Object.values(value)[0];
      }
      return dias;
    },
    obtenerHorarios() {
      axios({
        method: "GET",
        url: `/horario/horarios`,
        withCredentials: true,
      })
        .then((data) => {
          this.horarios = data.data.horarios;
        })
        .catch(() => {
        });
    },
    GuardarCambios() {
      axios({
        method: "POST",
        url: `/horario`,
        data: { horarios: this.horarios },
        withCredentials: true,
      })
        .then((value) => {
          let okValue = "popUp guardada Correctamente";
          let notOkValue =
            "Ocurrio un error durante el proceso de actualizacion.";

          this.$bvModal
            .msgBoxOk(value.status === 200 ? okValue : notOkValue, {
              size: "sm",
              centered: true,
              footerClass: "justify-content-center",
              okTitle: "Aceptar",
            })
            .then(() => {
              this.obtenerHorarios();
            });
        })
        .catch(() => {
        });
    },
  },
};
</script>
<style scoped>
input {
  text-align: center;
}
.accordion {
  /* OK */
  display: flex;
}
h5 {
  margin-right: 8px;
}
.box {
  margin-top: 5px;
  margin-bottom: 5px;
}
.datePosition {
  display: -webkit-inline-box;
}
.datePosition div {
  margin-right: 5px;
}

input,
select {
  border-radius: 15px;
  box-sizing: border-box;
  width: 100%;
  border: 2px solid rgb(115, 123, 131);
  height: 35px;
}
.pointer :hover {
  cursor: pointer;
}
.timeHour {
  padding: 0;
  margin: 0;
}
.custom-color-menu {
  flex-wrap: wrap;
  min-width: 152px;
  width: 152px;
  max-width: 152px;
}
.btn-group,
btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: inline-flex;
  vertical-align: middle;
}
@media screen and (min-width: 990px) {
  .headerEditor {
    /* display: flex; */
  }
}
</style>

import { Node } from 'tiptap';
import { replaceText } from 'tiptap-commands';

export default class FieldsList extends Node {
  get name() {
    return 'fieldsList';
  }

  get schema() {
    return {
      attrs: {
        id: {},
        listName: {},
        fields: {},
      },
      group: 'inline',
      inline: true,
      selectable: true,
      atom: true,
      toDOM: (node) => {
        let tableHeaders = node.attrs.fields.map((field) => {
          return ['th', { style: 'border: 1px solid' }, field];
        });

        return [
          'div',
          {
            class: 'custom-table',
            'data-field-table-id': node.attrs.id,
            id: node.attrs.id,
          },
          `${node.attrs.listName}`,
          [
            'table',
            {
              style: 'width: 100%',
            },
            ['thead', {}, ['tr', {}, ...tableHeaders]],
          ],
        ];
      },
      parseDOM: [
        {
          tag: 'div[data-field-table-id]',
          getAttrs: (dom) => {
            const id = dom.getAttribute('data-field-table-id');
            const listName = dom.innerHTML.substring(0, dom.innerHTML.indexOf('<'));
            const htmlHeaders = dom.getElementsByTagName('th');
            let fields = [];
            for (var i in htmlHeaders) {
              if (htmlHeaders[i].textContent) {
                fields.push(htmlHeaders[i].textContent);
              }
            }
            return { id, listName, fields };
          },
        },
      ],
    };
  }

  commands({ schema }) {
    return (attrs) => {
      if (attrs && attrs.id) {
        return replaceText(null, schema.nodes[this.name], attrs);
      }
    };
  }
}

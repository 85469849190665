import { Mark } from 'tiptap';
import { removeMark, toggleMark } from 'tiptap-commands';

/**
 * FontSize - Extends the built in Mark from tiptap allowing
 * the editor to <drumroll> set the font-size.
 */
export default class TextColor extends Mark {
  get name() {
    return 'textColor';
  }

  get schema() {
    return {
      attrs: { textColor: { default: 'rgb(255 ,255, 255)' } },
      parseDOM: [
        {
          style: 'color',
          getAttrs: (value) => (value ? { textColor: value } : ''),
        },
      ],
      toDOM: (mark) => ['span', { style: `color: ${mark.attrs.textColor}` }, 0],
    };
  }

  commands({ type }) {
    return (attrs) => {
      if (attrs.textColor && attrs.textColor != null) {
        return toggleMark(type, attrs);
      }
      return removeMark(type);
    };
  }
}

import { Mark } from 'tiptap';
import { removeMark, toggleMark } from 'tiptap-commands';

/**
 * FontSize - Extends the built in Mark from tiptap allowing
 * the editor to <drumroll> set the font-size.
 */
export default class BackgroundColor extends Mark {
  get name() {
    return 'backgroundColor';
  }

  get schema() {
    return {
      attrs: { backgroundColor: { default: 'rgb(0, 0, 0)' } },
      parseDOM: [
        {
          style: 'background',
          getAttrs: (value) => (value ? { backgroundColor: value } : ''),
        },
      ],
      toDOM: (mark) => ['span', { style: `background: ${mark.attrs.backgroundColor}` }, 0],
    };
  }

  commands({ type }) {
    return (attrs) => {
      if (attrs.backgroundColor && attrs.backgroundColor != null) {
        return toggleMark(type, attrs);
      }
      return removeMark(type);
    };
  }
}
